<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Print View
                    </div>
                    <div class="card-body">
                        <div class="row" id="myelement">
                            <div class="col-md-12" style="border: 1px solid #000000;padding-right: 0px; padding-left: 0px;">
                                <div class="col-md-12" style="height: 50px;border-bottom: 1px solid #000000;">
                                    <p style="margin-top: 7px;margin-bottom: 0px;margin-left: 30%;font-size: 19px;font-weight: bold;">ငြိမ်း လိုင်စင်ရ ပုဂ္ဂလိကငွေချေးလုပ်ငန်း ( တောင်ဒဂုံ ) 
                                    <span style="margin-left: 1%;">No. {{mortgageRequest.noDate}}-{{mortgageRequest.noType}}{{mortgageRequest.no}}</span></p>
                                    <p style="margin-left: 31%;font-size: 12px;margin-top: 0px;">အမှတ် - ၁၂၀၅ (တ/c) ၊ လှော်ကားလမ်း ၊ (၇၀)ရပ်ကွက်၊တောင်ဒဂုံမြို့နယ်။</p>
                                </div>
                                <div class="col-md-12" style="height: 240px;border-bottom: 1px solid #000000;">
                                    <div style="width: 100%; float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ပစ္စည်း အမျိုးအမည် </div>
                                        <div style="width: 78%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.type}}
                                        </div>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ကျောက်ပါ အလေးချိန် </div>
                                        <div style="width: 78%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.mortgageWeight.v1}} ကျပ်
                                            {{mortgageRequest.mortgageWeight.v1}} ပဲ
                                            {{mortgageRequest.mortgageWeight.v1}} ရွေး
                                        </div>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ချေးငွေ KS </div>
                                        <div style="width: 30%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.loan}}
                                        </div>
                                        <div style="float: left;width: 3%;padding-top: 4px;padding-left:4px;">ကျပ် </div>
                                        <div style="width: 20%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.loanMmk}}
                                        </div>
                                    </div>
                                     <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">( စာဖြင့် ) </div>
                                        <div style="width: 78%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.loanText}} ကျပ်
                                        </div>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ပေါင်နှံသူ အမည် </div>
                                        <div style="width: 30%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.name}}
                                        </div>
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">မှတ်ပုံတင် အမှတ် </div>
                                        <div v-if="mortgageRequest.nrc" style="width:10%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.nrc}}
                                        </div>
                                         <!-- <div v-else style="width:40%;border-bottom: 2px dotted #000000;float:left;margin-top: 23px;">
                                            {{mortgageRequest.nrc}}
                                        </div> -->
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">နေရပ်လိပ်စာ </div>
                                         <div style="width: 78%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.address}}
                                        </div>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">ပေါင်နှံသည့်နေ့စွဲ </div>
                                        <div style="width: 30%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.day}} <span>ရက်</span>
                                            {{mortgageRequest.month}}  <span>လ</span>
                                            {{mortgageRequest.year}}  <span>ခု</span>
                                        </div>
                                         <div style="float: left;width: 10%;padding-top: 4px;padding-left:4px;">အတိုးနှုန်း </div>
                                        <div style="width:20%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.interestRate}}
                                        </div>
                                    </div>
                                    <div  style="width: 100%;float: left;">
                                        <div style="float: left;width: 30%;padding-top: 4px;padding-left:4px;">အပေါင်သက်တမ်းကုန်ဆုံးမည့်နေ့  </div>
                                        <div style="width: 68%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                            {{mortgageRequest.endDate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" style="height: 200px;">
                                    <div style="width: 80%;height: 100%;float: left;">
                                        <div style="width: 60%;height: 100%;float: left;"></div>
                                        <div style="width: 30%;height: 100%;float: left;">
                                            <div style="width: 90%; height: 90%;float: left; border: 1px solid rgb(0, 0, 0);margin-left: 68px;margin-top: 10px;;">
                                                <div style="float:left;width: 100%;height: 20%;border-bottom: 1px solid #000000;">No {{mortgageRequest.noDate}}-{{mortgageRequest.noType}}{{mortgageRequest.no}}</div>
                                                <div style="float:left;width: 100%;height: 20%;border-bottom: 1px solid #000000;"></div>
                                                <div style="float:left;width: 100%;height: 20%;border-bottom: 1px solid #000000;">
                                                    <div style="float:left;width:30%;height: 100%;border-right: 1px solid #000000;font-size:10px;">
                                                        <span style="font-size: 11px;padding-top: 10px;">အလေးချိန်</span>
                                                    </div>
                                                    <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.mortgageWeight.v1}}</div>
                                                    <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.mortgageWeight.v2}}</div>
                                                    <div style="float:left;width:10%;height: 100%;">{{mortgageRequest.mortgageWeight.v3}}</div>
                                                </div>
                                                <div style="float:left;width: 100%;height: 20%;border-bottom: 1px solid #000000;">
                                                    <div style="float:left;width:30%;height: 100%;border-right: 1px solid #000000;font-size:10px;">
                                                        <span style="font-size: 11px;padding-top: 10px;">ကျောက်ချိန်</span>
                                                    </div>
                                                    <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.jewelWeight.v1}}</div>
                                                    <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.jewelWeight.v2}}</div>
                                                    <div style="float:left;width:10%;height: 100%;">{{mortgageRequest.jewelWeight.v3}}</div>
                                                </div>
                                                <div style="width: 100%;height: 20%;border-bottom: 1px solid #000000;">
                                                    <div style="float:left;width:30%;height: 100%;border-right: 1px solid #000000;font-size:10px;">
                                                        <span style="font-size: 11px;padding-top: 10px;">ရွှေချိန်</span>
                                                    </div>
                                                    <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.goldWeight.v1}}</div>
                                                    <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.goldWeight.v2}}</div>
                                                    <div style="float:left;width:10%;height: 100%;">{{mortgageRequest.goldWeight.v3}}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width: 10%;height: 100%; float: left;border-left: 1px solid #000000;">
                                        <div style="width: 200%;height: 30px;border-bottom: 1px solid #000000;"></div>
                                    </div>
                                </div>
                            </div>

                            <div style="width: 100%; height: 50px;"></div>

                            <div style="width: 100%;height: auto;border: 1px solid #000000;padding-right: 0px; padding-left: 0px;">
                                <div class="col-md-12" style="height: 50px;border-bottom: 1px solid #000000;">
                                    <p style="margin-top: 7px;margin-bottom: 0px;margin-left: 30%;font-size: 19px;font-weight: bold;">ငြိမ်း လိုင်စင်ရ ပုဂ္ဂလိကငွေချေးလုပ်ငန်း ( တောင်ဒဂုံ ) 
                                    <span style="margin-left: 1%;">No.{{mortgageRequest.noDate}}-{{mortgageRequest.noType}}{{mortgageRequest.no}}</span></p>
                                    <p style="margin-left: 31%;font-size: 12px;margin-top: 0px;">အမှတ် - ၁၂၀၅ (တ/c) ၊ လှော်ကားလမ်း ၊ (၇၀)ရပ်ကွက်၊တောင်ဒဂုံမြို့နယ်။</p>
                                </div>
                                <div style="width: 100%;height:250px;">
                                    <div style="float: left;width: 80%;height: 100%;border-right: 1px solid #000000;">
                                        <div style="width: 100%; float: left;">
                                            <div style="float: left;width: 28%;padding-top: 4px;padding-left:4px;">ပစ္စည်း အမျိုးအမည် </div>
                                            <div style="width: 70%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.type}}
                                            </div>
                                        </div>
                                        <div  style="width: 100%;float: left;">
                                            <div style="float: left;width: 28%;padding-top: 4px;padding-left:4px;">ကျောက်ပါ အလေးချိန် </div>
                                            <div style="width: 70%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.mortgageWeight.v1}} ကျပ်
                                                {{mortgageRequest.mortgageWeight.v1}} ပဲ
                                                {{mortgageRequest.mortgageWeight.v1}} ရွေး
                                            </div>
                                        </div>
                                        <div  style="width: 100%;float: left;">
                                            <div style="float: left;width: 28%;padding-top: 4px;padding-left:4px;">ချေးငွေ KS </div>
                                            <div style="width: 30%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.loan}}
                                            </div>
                                            <div style="float: left;width: 3%;padding-top: 4px;padding-left:4px;">ကျပ် </div>
                                            <div style="width: 20%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.loanMmk}}
                                            </div>
                                        </div>
                                        <div  style="width: 100%;float: left;">
                                            <div style="float: left;width: 28%;padding-top: 4px;padding-left:4px;">( စာဖြင့် ) </div>
                                            <div style="width: 70%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.loanMmk}} ကျပ်
                                            </div>
                                        </div>
                                        <div  style="width: 100%;float: left;">
                                            <div style="float: left;width: 28%;padding-top: 4px;padding-left:4px;">ပေါင်နှံသူ အမည် </div>
                                            <div style="width: 30%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.name}}
                                            </div>
                                             <div style="float: left;width: 20%;padding-top: 4px;padding-left:4px;">မှတ်ပုံတင် အမှတ် </div>
                                            <div v-if="mortgageRequest.nrc" style="width:10%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.nrc}}
                                            </div>
                                        </div>
                                        <div  style="width: 100%;float: left;">
                                            <div style="float: left;width: 28%;padding-top: 4px;padding-left:4px;">နေရပ်လိပ်စာ </div>
                                            <div style="width: 70%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.address}}
                                            </div>
                                        </div>
                                        <div  style="width: 100%;float: left;">
                                            <div style="float: left;width: 28%;padding-top: 4px;padding-left:4px;">ပေါင်နှံသည့်နေ့စွဲ </div>
                                            <div style="width: 30%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.day}} <span>ရက်</span>
                                                {{mortgageRequest.month}}  <span>လ</span>
                                                {{mortgageRequest.year}}  <span>ခု</span>
                                            </div>
                                            <div style="float: left;width: 10%;padding-top: 4px;padding-left:4px;">အတိုးနှုန်း </div>
                                            <div style="width:20%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.interestRate}}
                                            </div>
                                        </div>
                                        <div  style="width: 100%;float: left;">
                                            <div style="float: left;width: 38%;padding-top: 4px;padding-left:4px;">အပေါင်သက်တမ်းကုန်ဆုံးမည့်နေ့  </div>
                                            <div style="width: 60%;border-bottom: 2px dotted #000000;float:left;margin-top: 4px;">
                                                {{mortgageRequest.endDate}}
                                            </div>
                                        </div>
                                    </div>
                                    <div style="float: left;width:15%;height: 100%;">
                                        <div style="float:left;width: 134%;height:50%;border-bottom: 1px solid #000000;"></div>      
                                        <div style="float:left;width: 134%;height:10%;border-bottom: 1px solid #000000;">No {{mortgageRequest.noDate}}-{{mortgageRequest.noType}}{{mortgageRequest.no}}</div>  
                                        <div style="float:left;width: 134%;height:10%;border-bottom: 1px solid #000000;"></div>  
                                        <div style="float:left;width: 134%;height: 10%;border-bottom: 1px solid #000000;">
                                            <div style="float:left;width:30%;height: 100%;border-right: 1px solid #000000;font-size:10px;">
                                                <span style="font-size: 11px;padding-top: 10px;">အလေးချိန်</span>
                                            </div>
                                            <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.mortgageWeight.v1}}</div>
                                            <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.mortgageWeight.v1}}</div>
                                            <div style="float:left;width:10%;height: 100%;">{{mortgageRequest.mortgageWeight.v1}}</div>
                                        </div>
                                        <div style="float:left;width: 134%;height: 10%;border-bottom: 1px solid #000000;">
                                            <div style="float:left;width:30%;height: 100%;border-right: 1px solid #000000;font-size:10px;">
                                                <span style="font-size: 11px;padding-top: 10px;">ကျောက်ချိန်</span>
                                            </div>
                                            <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.jewelWeight.v1}}</div>
                                            <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.jewelWeight.v2}}</div>
                                            <div style="float:left;width:10%;height: 100%;">{{mortgageRequest.jewelWeight.v3}}</div>
                                        </div>
                                        <div style="width: 134%;height: 10%;border-bottom: 1px solid #000000;">
                                            <div style="float:left;width:30%;height: 100%;border-right: 1px solid #000000;font-size:10px;">
                                                <span style="font-size: 11px;padding-top: 10px;">ရွှေချိန်</span>
                                            </div>
                                            <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.goldWeight.v1}}</div>
                                            <div style="float:left;width:20%;height: 100%;border-right: 1px solid #000000;">{{mortgageRequest.goldWeight.v2}}</div>
                                            <div style="float:left;width:10%;height: 100%;">{{mortgageRequest.goldWeight.v3}}</div>
                                        </div>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                             <button class="form-control" style="width: 10%;" @click="print()"> <i class="fas fa-print"></i> Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css'
    import Printd from 'printd'
    import moment from 'moment';
    export default {
        name: 'print',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                mortgageRequest: {
					id: "",
                    no: "",
					itemId: "",
					type: "",
					mortgageWeight: {v1: "", v2: "", v3: ""},
					loan: "",
					loanMmk: "",
					loanText: "",
					name: "",
					nrc: "",
					address: "",
					startDate: "",
                    day: "",
					month: "",
					year: "",
					endDate: "",
					interestType: "percent",
					interestRate: 2,
					keep: '',
					loss: '',
					goldWeight: {v1: "", v2: "", v3: ""},
					jewelWeight: {v1: "", v2: "", v3: ""},
					weight: {v1: "", v2: "", v3: ""}
				},
            }
        },
        computed: {},
        methods: {
            ...mapActions({
				mortgageDetailAction: 'mortgageDetailAction',
			}),
            			async getDetail(){
				let option = {
					id:  this.$route.query.id
				}
				await this.mortgageDetailAction({
					...option
				}).then(res => {
					this.dataList = res.data.data
					this.mortgageRequest.id =  res.data.data.id
                    this.mortgageRequest.no =  res.data.data.no
                    this.mortgageRequest.noDate =  res.data.data.no_date
                    this.mortgageRequest.noType =  res.data.data.no_type
					this.mortgageRequest.itemId =  res.data.data.item_id
					this.mortgageRequest.type = res.data.data.type
					this.mortgageRequest.mortgageWeight.v1 = res.data.data.mortgage_weight.v1 
					this.mortgageRequest.mortgageWeight.v2 = res.data.data.mortgage_weight.v2
					this.mortgageRequest.mortgageWeight.v3 = res.data.data.mortgage_weight.v2
					this.mortgageRequest.loan = res.data.data.loan
					this.mortgageRequest.loanMmk = res.data.data.loan_mmk
					this.mortgageRequest.loanText = res.data.data.loan_text
					this.mortgageRequest.name = res.data.data.name 
					this.mortgageRequest.nrc = res.data.data.nrc
					this.mortgageRequest.address = res.data.data.address
					this.mortgageRequest.interestType = res.data.data.interest_type
					this.mortgageRequest.interestRate = res.data.data.interest_rate 
					this.mortgageRequest.keep = res.data.data.keep 
					this.mortgageRequest.loss = res.data.data.loss 
					this.mortgageRequest.startDate = res.data.data.start_date 
                    this.mortgageRequest.day = moment(res.data.data.start_date ).format('DD')
					this.mortgageRequest.month = moment(res.data.data.start_date ).format('MM')
					this.mortgageRequest.year = moment(res.data.data.start_date ).format('YY')
					this.mortgageRequest.endDate = moment(res.data.data.end_date ).format('DD-MM-y')
					this.mortgageRequest.goldWeight.v1 = res.data.data.gold.v1
					this.mortgageRequest.goldWeight.v2 = res.data.data.gold.v2
					this.mortgageRequest.goldWeight.v3 = res.data.data.gold.v3
					this.mortgageRequest.jewelWeight.v1 = res.data.data.jewel.v1
					this.mortgageRequest.jewelWeight.v2 = res.data.data.jewel.v2
					this.mortgageRequest.jewelWeight.v3 = res.data.data.jewel.v3
					this.mortgageRequest.weight.v1 = res.data.data.weight.v1
					this.mortgageRequest.weight.v2 = res.data.data.weight.v2
					this.mortgageRequest.weight.v3 = res.data.data.weight.v3
					let month = moment(res.data.data.start_date).format('M')
					if(this.mortgageRequest.filterType == 'type2'){
						this.mortgageRequest.referenceNote = month+"×"+res.data.data.no+"(လဲ)"
					}
					if(this.mortgageRequest.filterType == 'type3'){
						this.mortgageRequest.referenceNote = month+"×"+res.data.data.no+"(သွင်း)"
					}
					if(this.mortgageRequest.filterType == 'type4'){
						this.mortgageRequest.referenceNote = month+"×"+res.data.data.no+"(ယူ)"
					}
					this.isLoading = false
					}).catch(err => this.isLoading = true)
			},
            print() {
                const d = new Printd()
                d.print( document.getElementById('myelement') )
            },

        },
        async mounted() {
            this.getDetail()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>